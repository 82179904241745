import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import './HomeContactoMapaMobile.css';

const HomeContactoMapaMobile = ({ onCountrySelect, selectedCountry, countries }) => {
  const canvasRef = useRef(null);
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const width = 300;
    const height = 300;
    const sphere = { type: "Sphere" };

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const projection = d3.geoOrthographic()
      .scale(100)
      .translate([width/2, height/2])
      .rotate([60, 0])
      .clipAngle(90);

    const path = d3.geoPath(projection, context);

    d3.json("https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json").then(worldData => {
      const land = topojson.feature(worldData, worldData.objects.land);
      const countriesBorders = topojson.mesh(worldData, worldData.objects.countries, (a, b) => a !== b);

      const drawMap = () => {
        context.clearRect(0, 0, width, height);
        
        // Aplicar sombra
        context.shadowBlur = 30;            // Tamaño de la sombra
        context.shadowOffsetX = 10;         // Desplazamiento en el eje X
        context.shadowOffsetY = 10;         // Desplazamiento en el eje Y
        context.shadowColor = "rgba(0, 0, 0, 0.5)"; // Color de la sombra (semi-transparente)

        // Dibujar el globo terráqueo
        context.beginPath();
        path(sphere);
        context.fillStyle = "#7CAED4";
        context.fill();

        // Limpiar la sombra para evitar que afecte a otros elementos
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        context.shadowColor = "transparent";

        // Dibujar los países
        land.features.forEach(d => {
          context.beginPath();
          path(d);
          context.fillStyle = "#F4F4F4";
          context.fill();
        });

        // Dibujar bordes de países
        context.beginPath();
        path(countriesBorders);
        context.strokeStyle = "#333";
        context.lineWidth = 0.5;
        context.stroke();

        // Dibujar los pines de los países
        countries.forEach(country => {
          const [x, y] = projection([country.longitud, country.latitud]);
          context.beginPath();
          context.arc(x, y, selectedCountry?.name === country.name ? 7 : 5, 0, 2 * Math.PI);
          context.fillStyle = "#A3C537";
          context.fill();

          if (selectedCountry?.name !== country.name) {
            context.strokeStyle = "#4A4A49";
            context.lineWidth = 0.5;
            context.stroke();
          }
        });
      };

      drawMap();

      // Manejo del evento de click para seleccionar país
      canvas.addEventListener("click", (event) => {
        const mouse = [event.offsetX, event.offsetY];
        countries.forEach(country => {
          const [x, y] = projection([country.longitud, country.latitud]);
          const distance = Math.sqrt((x - mouse[0]) ** 2 + (y - mouse[1]) ** 2);
          if (distance < 7) {
            onCountrySelect(country);
          }
        });
      });

      // Manejo del hover
      canvas.addEventListener("mousemove", (event) => {
        const mouse = [event.offsetX, event.offsetY];
        let foundCountry = null;

        countries.forEach(country => {
          const [x, y] = projection([country.longitud, country.latitud]);
          const distance = Math.sqrt((x - mouse[0]) ** 2 + (y - mouse[1]) ** 2);
          if (distance < 7) {
            foundCountry = country;
            setPopupPosition({ x: event.pageX + 20, y: event.pageY - 50 });
          }
        });

        setHoveredCountry(foundCountry);
      });

    }).catch(error => {
      console.error("Error al cargar los datos del mundo", error);
    });
  }, [onCountrySelect, selectedCountry, countries]);

  return (
    <>
      <canvas ref={canvasRef} width={300} height={300} style={{ background: "none" }}></canvas>
      {hoveredCountry && (
        <div
          className="home-contacto-mapa-popup"
          style={{
            position: 'absolute',
            top: `${popupPosition.y}px`,
            left: `${popupPosition.x}px`,
            backgroundColor: '#aace3b',
            padding: '0.7rem',
            borderRadius: '5px',
            border: '2px solid white',
            fontSize: '1rem',
            color: '#4A4A49',
            fontWeight: '600',
          }}
        >
          {hoveredCountry.name}
        </div>
      )}
    </>
  );
};

export default HomeContactoMapaMobile;
