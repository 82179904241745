// src/components/Noticia/NoticiaUltimas.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NoticiaUltimas.css';

const NoticiaUltimas = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/noticias?populate=previsualizacion`
        );
        const { data } = await response.json();

        const noticiasArray = data.map((noticia) => ({
          id: noticia.id,
          titulo: noticia.titulo || 'Sin título',
          previsualizacion:
            noticia.previsualizacion?.[0]?.formats?.thumbnail?.url ||
            noticia.previsualizacion?.[0]?.url ||
            '',
          slug: noticia.slug,
        }));

        setNoticias(noticiasArray.slice(0, 3));
      } catch (error) {
        console.error('Error fetching noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <div className="noticia-ultimas">
      <h3 className="noticia-ultimas-title">Últimas noticias</h3>
      <div className="noticia-ultimas-cards">
        {noticias.map((noticia) => (
          <Link
            key={noticia.id}
            to={`/noticias/${noticia.slug}`}
            className="noticia-ultimas-card"
          >
            {noticia.previsualizacion && (
              <img
                src={noticia.previsualizacion}
                alt={noticia.titulo}
                className="noticia-ultimas-card-img"
              />
            )}
            <div className="noticia-ultimas-card-content">
              <h4 className="noticia-ultimas-card-title">{noticia.titulo}</h4>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NoticiaUltimas;
