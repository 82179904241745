// src/pages/Noticia.js

import React, { useEffect, useState } from 'react';
import PageFooter from '../components/PageFooter';
import { useParams } from 'react-router-dom';
import NoticiaSocial from '../components/Noticia/NoticiaSocial';
import NoticiaUltimas from '../components/Noticia/NoticiaUltimas';
import './Noticia.css';

const Noticia = () => {
  const { slug } = useParams();
  const [noticia, setNoticia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/noticias?filters[slug][$eq]=${slug}&populate=imagen,previsualizacion`
        );
        const data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setNoticia(data.data[0]);
        } else {
          setError('Noticia no encontrada');
        }
      } catch (error) {
        console.error('Error fetching noticia:', error);
        setError('Error al cargar la noticia');
      } finally {
        setLoading(false);
      }
    };

    fetchNoticia();
  }, [slug]);

  if (loading) {
    return <div className="noticias-page-loading">Cargando...</div>;
  }

  if (error) {
    return <div className="noticias-page-error">{error}</div>;
  }

  if (!noticia) {
    return <div className="noticias-page-not-found">No se encontró la noticia.</div>;
  }

  const {
    titulo,
    bajada,
    contenido,
    createdAt,
    imagen,
  } = noticia;

  const formattedCreatedAt = createdAt
    ? new Date(createdAt).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : 'Fecha no disponible';

  const imagenUrl = imagen?.formats?.large?.url || imagen?.url || null;

  return (
    <div className="noticia-page-container">
      <div className="noticia-page-content-wrapper">
        {/* Left Column */}
        <div className="noticia-page-content">
          <h1 className="noticia-page-titulo">{titulo}</h1>
          <p className="noticia-page-fecha">{formattedCreatedAt}</p>
          {imagenUrl && (
            <img
              src={imagenUrl}
              alt={titulo}
              className="noticia-page-imagen"
            />
          )}
          <h2 className="noticia-page-bajada">{bajada}</h2>
          <div
            className="noticia-page-contenido"
            dangerouslySetInnerHTML={{ __html: contenido }}
          />
        </div>

        {/* Right Column */}
        <div className="noticia-page-secondary">
          <NoticiaSocial />
          <NoticiaUltimas />
        </div>
      </div>

      <div className="noticia-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Noticia;
