import React from 'react';
import MapaImage from '../../../../images/HomeContactoSectionDesktop/home-contacto-section-mapa.webp'; // Importamos la imagen
import HomeContactoCardDesktop from './HomeContactoCardDesktop';
import './HomeContactoSectionDesktop.css';

const HomeContactoSectionDesktop = ({
  selectedCountry,
  countries,
  onCountrySelect,
}) => {

  if (countries.length === 0) {
    return <div className="home-contacto-section-desktop-cargando">Cargando...</div>;
  }

  return (
    <div className="home-contacto-section-desktop-container">
      <div className="home-contacto-section-desktop-mapa-container">
        <img className='home-comtacto-section-desktop-mapa' 
        src={MapaImage} 
        alt="Mapa">
        </img>
      </div>
      <div className="home-contacto-section-desktop-card">
        <HomeContactoCardDesktop
          selectedCountry={selectedCountry}
          onCountryChange={onCountrySelect}
          countries={countries}  // Pasamos los países a la tarjeta
        />
      </div>
    </div>
  );
};

export default HomeContactoSectionDesktop;
