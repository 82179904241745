// src/components/Modelo/ModeloDestacadoMobile/ModeloDestacadoMobileCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ModeloDestacadoMobileCard.css';

const ModeloDestacadoMobileCard = ({ modelo, slug, imagenCard }) => {
  const imagenCardUrl = imagenCard || '/images/General/image_not_available.webp';  // Fallback si no hay imagen

  return (
    <div className="modelo-destacado-mobile-card">
      <div className="modelo-destacado-mobile-card-imagen">
        <img 
          src={imagenCardUrl} 
          alt={`Imagen de ${modelo}`} 
        />
      </div>
      <div className="modelo-destacado-mobile-card-header">
        <h3>{modelo}</h3>
      </div>
      <div className="modelo-destacado-mobile-card-boton-wrapper">
        <Link to={`/modelo/${slug}`} className="modelo-destacado-mobile-card-boton">
          Consultar
        </Link>
      </div>
    </div>
  );
};

export default ModeloDestacadoMobileCard;
