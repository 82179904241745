// src/components/HomeBannerSection.js

import React, { useState, useEffect } from 'react';
import HomeBannerDesktop from './HomeBannerDesktop/HomeBannerDesktop';
import HomeBannerMobile from './HomeBannerMobile/HomeBannerMobile';
import './HomeBannerSection.css';

const HomeBannerSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home-banner-section-container">
      {isMobile ? <HomeBannerMobile /> : <HomeBannerDesktop />}
    </div>
  );
};

export default HomeBannerSection;
