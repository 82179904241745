import React, { useState, useEffect } from 'react';
import HomeContactoSectionDesktop from './HomeContactoSectionDesktop/HomeContactoSectionDesktop';
import HomeContactoSectionMobile from './HomeContactoSectionMobile/HomeContactoSectionMobile';
import './HomeContactoSection.css'; // Asegúrate de tener las clases home-contacto-section-

const HomeContactoSection = () => {
  const [selectedCountry, setSelectedCountry] = useState(null); // Estado para el país seleccionado
  const [countries, setCountries] = useState([]); // Estado para almacenar los países y oficinas
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detectar si es móvil o no

  // Efecto para ajustar el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Efecto para cargar las oficinas al montar el componente
  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/oficinas?populate=d3map`);
        const result = await response.json();
        {/*}
        console.log('API Response:', result); // Verifica la estructura de la respuesta
        {*/}

        if (Array.isArray(result.data)) {
          const formattedData = result.data.map((oficina) => {
            // Procesar los datos de d3map directamente
            const d3mapCoords = oficina.d3map && oficina.d3map.length > 0
              ? {
                  latitud: oficina.d3map[0]?.latitud || null,
                  longitud: oficina.d3map[0]?.longitud || null,
                }
              : {
                  latitud: null,
                  longitud: null,
                };

            return {
              name: oficina.pais || 'Sin país', // Manejar valores faltantes
              email: oficina.correo || 'Sin correo',
              direccion: oficina.direccion || 'Sin dirección',
              representante: oficina.representante || 'Sin representante',
              telefono: oficina.telefono || 'Sin teléfono',
              ...d3mapCoords, // Añadir latitud y longitud de d3map
            };
          });

          setCountries(formattedData);
          setLoading(false);

          // Seleccionar el primer país por defecto si no hay ninguno seleccionado
          setSelectedCountry(null); // No seleccionar ningún país por defecto
        } else {
          console.error('Unexpected data format:', result);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error al obtener las oficinas:', error);
        setLoading(false);
      }
    };

    fetchOficinas();
  }, []);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  if (loading) {
    return <div className="home-contacto-section-cargando">Cargando...</div>;
  }

  return (
    <div className="home-contacto-section-container">
      {isMobile ? (
        <HomeContactoSectionMobile
          onCountrySelect={handleCountrySelect}
          selectedCountry={selectedCountry}
          countries={countries}
        />
      ) : (
        <HomeContactoSectionDesktop
          onCountrySelect={handleCountrySelect}
          selectedCountry={selectedCountry}
          countries={countries}
        />
      )}
    </div>
  );
};

export default HomeContactoSection;
