import React from 'react';
import HomeLineaSliderMobile from './HomeLineaSliderMobile';  // Componentes adaptados para móvil
import HomeSerieSliderMobile from './HomeSerieSliderMobile';
import './HomeShowcaseMobile.css'; // Estilos personalizados para móvil

function HomeShowcaseMobile({ lineas, currentLinea, currentLineaSeries, handleLineaChange, lineaSliderRef, serieSliderRef, loading }) {
  return (
    <div className="home-showcase-mobile-container">
      
      <div className="home-showcase-mobile-lineas-wrapper">
        <HomeLineaSliderMobile 
          lineas={lineas} 
          onLineaChange={handleLineaChange} 
          sliderRef={lineaSliderRef} 
          loading={loading} 
        />
      </div>

      <div className="home-showcase-mobile-series-wrapper">
        <HomeSerieSliderMobile 
          series={currentLineaSeries} 
          sliderRef={serieSliderRef} 
          loading={loading} 
          linea={lineas.find(linea => linea.id === currentLinea)} 
        />
      </div>

      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseMobile;
