// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module';

// Configura Google Tag Manager con el ID del contenedor
const tagManagerArgs = {
  gtmId: 'GTM-KXJZ8QLF',
};

TagManager.initialize(tagManagerArgs); // Inicializa GTM

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
