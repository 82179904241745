// File: src/components/Submenu.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Submenu.css';

const Submenu = ({ onClose }) => {
  const [lineas, setLineas] = useState([]);
  const [activeLinea, setActiveLinea] = useState(null);
  const [activeSerie, setActiveSerie] = useState(null);
  const [modelos, setModelos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lineas?`
        );
        const data = await response.json();

        if (data && data.data) {
          const fetchedLineas = data.data.map((item) => ({
            documentId: item.documentId,
            nombre: item.nombre || 'Sin nombre',
            image: item.navegacion?.url || '',
            posicion: item.posicion || 0,
            series: (item.series || []).map((serie) => ({
              documentId: serie.documentId,
              nombre: serie.nombre || 'Sin nombre',
              image: serie.imagen_landing?.[0]?.url || '',
              posicion: serie.posicion || 0,
              modelos: (serie.modelos || []).map((modelo) => ({
                documentId: modelo.documentId,
                nombre: modelo.nombre || 'Sin nombre',
                slug: modelo.slug || '',
              })),
            })),
          }));

          const sortedLineas = fetchedLineas.sort((a, b) => a.posicion - b.posicion);

          sortedLineas.forEach((linea) => {
            linea.series = linea.series.sort((a, b) => a.posicion - b.posicion);
          });

          setLineas(sortedLineas);
          setLoading(false);
        } else {
          console.error('Error en la estructura de los datos:', data);
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, []);

  const handleLineaHover = (linea) => {
    setActiveLinea(linea);
    setActiveSerie(null);
    setModelos([]);
  };

  const handleSerieHover = (serie) => {
    setActiveSerie(serie);
    setModelos(serie.modelos);
  };

  const handleSerieClick = (serie) => {
    if (activeLinea && serie) {
      setActiveSerie(serie);
      navigate('/productos', {
        state: {
          linea: activeLinea,
          serie: serie,
        },
      });
      onClose();
    } else {
      console.error('No se ha seleccionado ninguna línea o serie.');
    }
  };

  const handleSeeMoreClick = () => {
    if (activeLinea && activeSerie) {
      navigate('/productos', {
        state: {
          linea: activeLinea,
          serie: activeSerie,
        },
      });
      onClose();
    } else {
      console.error('No se ha seleccionado ninguna línea o serie.');
    }
  };

  const handleModelClick = (modelSlug) => {
    window.gtag('event', 'select_model', {
      model_slug: modelSlug,
      event_category: 'navigation',
      event_label: 'Submenu Model Click',
      value: 1,
    });

    navigate(`/modelo/${modelSlug}`);
    onClose();
  };

  return (
    <div className="navbar-submenu">
      {loading ? (
        <div>Cargando productos...</div>
      ) : (
        <>
          <div className="navbar-submenu-column-1">
            {lineas.map((linea) => (
              <div
                key={linea.documentId}
                className={`navbar-submenu-linea ${
                  activeLinea?.documentId === linea.documentId ? 'navbar-submenu-bold active' : ''
                }`}
                onMouseEnter={() => handleLineaHover(linea)}
                onClick={() => setActiveLinea(linea)}
              >
                <span
                  className={activeLinea?.documentId === linea.documentId ? 'navbar-submenu-bold' : ''}
                >
                  {linea.nombre}
                </span>
                {linea.image && (
                  <img src={linea.image} alt={linea.nombre} className="navbar-submenu-icon" />
                )}
              </div>
            ))}
          </div>
          <div className="navbar-submenu-column-2">
            {activeLinea && activeLinea.series.length > 0 ? (
              activeLinea.series.map((serie) => (
                <div
                  key={serie.documentId}
                  className={`navbar-submenu-serie ${
                    activeSerie?.documentId === serie.documentId ? 'navbar-submenu-bold active' : ''
                  }`}
                  onClick={() => handleSerieClick(serie)}
                  onMouseEnter={() => handleSerieHover(serie)}
                >
                  <span
                    className={activeSerie?.documentId === serie.documentId ? 'navbar-submenu-bold' : ''}
                  >
                    {serie.nombre}
                  </span>
                </div>
              ))
            ) : (
              activeLinea && (
                <div className="navbar-submenu-no-series">No hay series en esta línea</div>
              )
            )}
          </div>
          <div className="navbar-submenu-column-3">
            {activeSerie && (
              <>
                {modelos.length > 0 ? (
                  modelos.slice(0, 8).map((modelo) => (
                    <div
                      key={modelo.documentId}
                      className="navbar-submenu-modelo"
                      onClick={() => handleModelClick(modelo.slug)}
                    >
                      <span>{modelo.nombre}</span>
                    </div>
                  ))
                ) : (
                  <div className="navbar-submenu-no-modelos">
                    No hay modelos disponibles para la serie seleccionada.
                  </div>
                )}
                {modelos.length > 8 && (
                  <div className="navbar-submenu-ver-mas" onClick={handleSeeMoreClick}>
                    Ver más
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Submenu;
