// Ruta: src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PageNavbarContainer from './components/General/PageNavbarContainer';
import Home from './pages/Home';
import Contacto from './pages/Contacto';
import Productos from './pages/Productos';
import OfertaLaboral from './pages/Ofertas';
import Servicios from './pages/Servicios';
import Nosotros from './pages/Nosotros';
import Noticias from './pages/Noticias';
import Noticia from './pages/Noticia';
import Modelo from './pages/Modelo';
import Concurso from './pages/Concurso';
import FullPageWrapper from './components/FullPageWrapper';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import CookieConsent from './components/General/CookieConsent';

const NavbarSwitcher = () => {
  const location = useLocation();
  return location.pathname === '/ofertalaboral' || location.pathname === '/test' ? null : <PageNavbarContainer />;
};

const Layout = ({ children }) => {
  const location = useLocation();
  return <div className={location.pathname === '/' ? 'home-page' : 'default-page'}>{children}</div>;
};

const OpenPDF = ({ pdfUrl }) => {
  window.location.href = pdfUrl;
  return null;
};

const App = () => {
  return (
    <Router>
      <Layout>
        <NavbarSwitcher />
        <ErrorBoundary>
          <Routes>
            <Route
              path="/"
              element={
                <FullPageWrapper>
                  <Home />
                </FullPageWrapper>
              }
            />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/productos" element={<Productos />} />
            <Route path="/ofertalaboral" element={<OfertaLaboral />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/:slug" element={<Noticia />} />
            <Route path="/modelo/:slug" element={<Modelo />} />
            <Route path="/concurso" element={<Concurso />} />

            <Route path="/privacidad/peru" element={<OpenPDF pdfUrl="/privacidad/privacidad_peru.pdf" />} />
            <Route path="/privacidad/chile" element={<OpenPDF pdfUrl="/privacidad/privacidad_chile.pdf" />} />
            <Route path="/privacidad/colombia" element={<OpenPDF pdfUrl="/privacidad/privacidad_colombia.pdf" />} />

            <Route path="/cookies" element={<OpenPDF pdfUrl="/cookies/cookies.pdf" />} />
          </Routes>
        </ErrorBoundary>
        <CookieConsent />
      </Layout>
    </Router>
  );
};

export default App;
