import React, { useEffect, useState } from 'react';

const ProductDataProvider = ({ children }) => {
  const [lineas, setLineas] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState(null);
  const [modelos, setModelos] = useState([]);
  const [loadingLineas, setLoadingLineas] = useState(true);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [error, setError] = useState(null);

  // Cargar las líneas y series al iniciar
  useEffect(() => {
    const fetchLineas = async () => {
      console.log('Fetching líneas, series y modelos...');
      setLoadingLineas(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=series`);
        const result = await response.json();
        if (!result.data) throw new Error('No se pudieron obtener las líneas.');

        console.log('Payload de líneas, series y modelos:', result);
        setLineas(result.data || []);
      } catch (err) {
        console.error('Error al obtener líneas:', err);
        setError(err.message);
      } finally {
        setLoadingLineas(false);
      }
    };

    fetchLineas();
  }, []);

  // Cargar modelos solo cuando se selecciona una serie
  useEffect(() => {
    const fetchModelos = async () => {
      if (!selectedSerie?.documentId) return; // Solo ejecutar si la serie es válida
      console.log('Fetching modelos para serie con documentId:', selectedSerie.documentId);
      setLoadingModelos(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modelos?filters[serie][documentId][$eq]=${selectedSerie.documentId}`
        );
        const result = await response.json();
        if (!result.data) throw new Error('No se pudieron obtener los modelos relacionados con la serie.');

        const parsedModelos = result.data.map((modelo) => ({
          documentId: modelo.documentId || null,
          nombre: modelo.nombre || 'Sin nombre',
          descripcion: modelo.descripcion || null,
          slug: modelo.slug || null,
          especificaciones: modelo.especificaciones || [],
          imagen_card: modelo.imagen_card?.url || null,
        }));

        console.log('Modelos parseados:', parsedModelos);
        setModelos(parsedModelos);
      } catch (err) {
        console.error('Error al obtener modelos relacionados con la serie:', err);
        setError(err.message);
      } finally {
        setLoadingModelos(false);
      }
    };

    fetchModelos();
  }, [selectedSerie]); // Solo ejecutar cuando cambia selectedSerie

  return children({
    lineas,
    selectedSerie,
    setSelectedSerie,
    modelos,
    loadingLineas,
    loadingModelos,
    error,
  });
};

export default ProductDataProvider;
