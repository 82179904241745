// src/components/Modelo/ModeloDestacadoMobile/ModeloDestacadoMobile.js
import React, { useEffect, useState } from 'react';
import ModeloDestacadoMobileCard from './ModeloDestacadoMobileCard';
import './ModeloDestacadoMobile.css';
import Slider from 'react-slick'; // Componente Slider de react-slick
import 'slick-carousel/slick/slick.css'; // Estilos de Slick
import 'slick-carousel/slick/slick-theme.css';

const ModeloDestacadoMobile = ({ linea }) => {
  const [destacados, setDestacados] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDestacados = async () => {
      if (!linea || !linea.documentId) {
        setError('No se pudo encontrar la línea seleccionada.');
        return;
      }

      try {
        // Fetch de la API con populate ajustado
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lineas?filters[documentId]=${linea.documentId}&populate=destacados.serie,destacados.imagen_card`
        );
        const data = await response.json();

        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error('Error fetching destacados');
        }

        // Accedemos a los destacados de la respuesta
        const destacadosData = data.data[0]?.destacados?.map((destacado) => {
          const { nombre, slug, descripcion, serie, imagen_card } = destacado;
          return {
            nombre,
            slug,
            descripcion,
            serie: serie?.nombre || 'Sin serie',
            imagenCard:
              imagen_card?.formats?.medium?.url || imagen_card?.url || null, // URL de la imagen (priorizamos formato 'medium')
          };
        });

        setDestacados(destacadosData.slice(0, 3)); // Limitamos a los primeros 3 destacados
      } catch (error) {
        console.error('Error fetching destacados:', error);
        setError('Error cargando los destacados.');
      }
    };

    fetchDestacados();
  }, [linea]);

  if (error) {
    return <div className="modelo-destacado-mobile-error">{error}</div>;
  }

  // Configuración de React Slick
  const settings = {
    dots: true, // Muestra puntos de navegación
    infinite: true, // Ciclo infinito de las diapositivas
    speed: 500, // Velocidad de transición en ms
    slidesToShow: 1, // Mostrar solo una tarjeta a la vez
    slidesToScroll: 1, // Avanzar una tarjeta a la vez
  };

  return (
    <div className="modelo-destacado-mobile-section">
      <div className="modelo-destacado-mobile-container">
        <h3 className="modelo-destacado-mobile-title">Productos Recomendados</h3>
        <div className="modelo-destacado-mobile-slider-wrapper">
          <Slider {...settings}>
            {destacados.length > 0 ? (
              destacados.map((modelo, index) => (
                <div key={index}>
                  <ModeloDestacadoMobileCard
                    modelo={modelo.nombre}
                    slug={modelo.slug}
                    descripcion={modelo.descripcion}
                    serie={modelo.serie} // Pasamos el nombre de la serie
                    imagenCard={modelo.imagenCard} // Pasamos la URL de la imagen
                  />
                </div>
              ))
            ) : (
              <div>No hay modelos destacados.</div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ModeloDestacadoMobile;
