// src/components/Modelo/ModeloFicha.js
import React from 'react';
import './ModeloFicha.css';

const ModeloFicha = ({ modelo }) => {
  const handleFichaClick = () => {
    // GA4 event for technical data sheet
    window.gtag('event', 'view_technical_sheet', {
      event_category: 'engagement',
      event_label: `Ficha técnica - ${modelo.nombre}`,
      value: 1
    });

    // Abrir la URL en una nueva pestaña
    window.open('https://heyzine.com/flip-book/f34195a0ca.html#page/1', '_blank');
  };

  return (
    <div className="modelo-ficha">
      <div className="modelo-ficha-button-container" onClick={handleFichaClick}>
        <div className="modelo-ficha-button-content">
          <span>VER EN CATÁLOGO</span>
        </div>
      </div>
    </div>
  );
};

export default ModeloFicha;
