// src/components/Modelo/ModeloDestacadoWrapper.js
import React, { useState, useEffect } from 'react';
import ModeloDestacadoDesktop from './ModeloDestacadoDesktop/ModeloDestacadoDesktop';
import ModeloDestacadoMobile from './ModeloDestacadoMobile/ModeloDestacadoMobile';

const ModeloDestacadoWrapper = ({ linea }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <ModeloDestacadoMobile linea={linea} />
      ) : (
        <ModeloDestacadoDesktop linea={linea} />
      )}
    </>
  );
};

export default ModeloDestacadoWrapper;
