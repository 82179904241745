import React, { useState, useEffect } from 'react';
import PageNavbarMobile from './PageNavbarMobile/PageNavbarMobile';
import PageNavbarDesktop from './PageNavbarDesktop/PageNavbarDesktop';

const PageNavbarContainer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Detectar cambios en el tamaño de la pantalla para decidir si mostrar mobile o desktop
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <PageNavbarMobile /> : <PageNavbarDesktop />}
    </div>
  );
};

export default PageNavbarContainer;
